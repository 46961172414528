
* {
  box-sizing: border-box;
  touch-action: manipulation;
  /* touch-action: none */
}

#root {
  height: 0;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

button {
  font-family: 'Montserrat', sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill
{
 -webkit-text-fill-color: #fff !important;
 font-size: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiTab-root{
  border: none;
  background: transparent
}

p{
  margin: 0;
}
