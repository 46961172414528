/* TODO удалить общие стили */

.formContainer {
  width: 340px;
  box-sizing: border-box;
  z-index: 5;
  margin: 0 auto;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.formContainer--recovery {
  width: 340px;
  margin: 0 auto;
}


@media screen and (min-width: 768px) {
  .formContainer {
    width: 396px;
    margin: 0 auto;
  }

  .formContainer--recovery {
    width: 470px;
  }
}

@media screen and (min-width: 1370px) {
    .formContainer {
        width: 480px;
        //margin-left: 107px;
    }

  .formContainer--recovery {
    //margin-left: 107px;
  }
}

@media screen and (min-width: 1920px) {
  .formContainer,
  .formContainer--recovery {
    margin-left: 264px;
  }
}


.textField > div > div > input {
  color: white !important;
}

.formContainer legend span {
  display: none !important;
}

.formWrapper {
    padding: 30px;
    background: linear-gradient(138.7deg, rgba(52, 58, 89, 0.85) 0%, rgba(52, 58, 89, 0.09) 98.81%);
    backdrop-filter: blur(24px);
    border-radius: 24px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

..enterGame {
    font-weight: 700; 
    font-family: 'Montserrat';
    font-size: 24px; 
    text-align: left;
    color: #FFFFFF;
    line-height: normal; 
    margin-bottom: 16px;


    @media (max-width: 1024px) and (min-width: 769px) {
        font-size: 2vw;
        font-weight: 400;
        margin-bottom: 1vw;
    }

    @media (max-width: 1000px) and (min-width: 700px) {
        font-size: 2.5vw;
        font-weight: 400;
        margin-bottom: 1vw;
    }

    @media (max-width: 500px) and (min-width: 300px) {
        font-size: 5vw;
        font-weight: 400;
        margin-bottom: 6vw;
    }
}

.joinGame--login {
  margin-bottom: 24px;
}

.joinGame--register {
  margin-bottom: 16px;
}

.joinGame, .recoveryText {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #969BAD;
}

.joinGame, .joinGame--recovery {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: #969BAD;
    margin-top: 16px; /* Добавьте верхний отступ для .joinGame--recovery */
}

.recoveryText {
    font-family: 'Montserrat';
    font-size: 13px;
    line-height: 1.7;
    font-weight: 400;
    color: #999999;
    transition: color 0.2s;
    margin: 10px 0;
}

.joinGame a,
.checkboxField__text--agreement a {
    color: #00BA6C;
    text-decoration: none;
    font-weight: 700;
}

.checkboxField__text--agreement {
  margin-bottom: 16px;
}

.joinGame--notFound {
  margin-top: 16px;
  font-weight: 400;
  line-height: 1.2;
  border-radius: 16px;
  padding: 6px 10px 0 0;
  font-family: 'Montserrat'
}

.formBtnWrap {
  display: flex;
  flex-direction: column;
}

.formBtnWrap a {
  display: block;
}

.formBtnWrap--login a {
  font-size: 16px;
}

.checkboxFieldReg {
  margin-top: 18px;
  display: block;
  color: #969BAD;
}

div.checkboxField-wrap {
  margin-bottom: 16px;
}

.formWrapper .MuiFormLabel-root.Mui-focused,
.formWrapper .MuiInputLabel-outlined.MuiInputLabel-shrink{
  transform: translate(14px, 6px) scale(0.75) !important;
}

.formWrapper .MuiOutlinedInput-input {
  padding: 22px 16px 14px 16px;
}

.hidePasswordBtn {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    border: none;
    opacity: 0.5;
    cursor: pointer;
    background: transparent no-repeat center;

    @media screen and (max-width: 1100px), screen and (max-width: 800px), screen and (max-width: 600px) {
        top: 45%;
    }
}

.hidePasswordBtnClosed {
    background-image: url("src/assets/icons/EyeClose.svg");
}

.hidePasswordBtnOpen {
    background-image: url("src/assets/icons/EyeOpen.svg");
}

.enterGameRecover {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  /* Chess */
  color: #fff;
}

.fieldGrid {
  width: 100%;
}

div.input-container {
  margin-bottom: 22px;
}

.checkboxField {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.checkboxField__text {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  color: rgba(80, 186, 174, 0.8);
  margin-left: 10px;
  transition: color 0.2s;
}

.checkboxField:hover .checkboxField__text{
  color: rgba(80, 186, 174, 1);
}


.error {
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #EB5757;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  padding-top: 10px;
}
.error::before {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  background-image: url('images/error.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

@media screen and (min-width: 1370px) {
  .formWrapper {
    padding: 30px;
    margin-top: 1vw,
  }

  .checkboxField__text {
    font-size: 17px;
    line-height: 1;
  }

  .formBtnWrap a {
    font-size: 20px;
  }

  .closeIcon {
    cursor: pointer;
    width: 0.9vw;
    height: 0.9vw;
    }

    @media (max-width: 1000px) {
        .closeIcon {
            width: 2vw;
            height: 2vw;
        }
    }

    @media (max-width: 500px) {
        .closeIcon {
            width: 3vw;
            height: 3vw;
            margin-bottom: 5vw;
        }
    }
    .outlinedLabel {
        color: #969BAD;
        font-family: 'Montserrat';
        font-size: 14px; /* Измените размер шрифта */
        font-weight: 400; /* Измените толщину шрифта */
        line-height: normal; /* Сбросьте высоту строки */
        transition: all 0.2s;
        width: 100%;
        padding-bottom: 8px; /* Измените нижний отступ */
        letter-spacing: normal; /* Сбросьте межбуквенный интервал */
    }

    @media (max-width: 1000px) {
        .outlinedLabel {
            font-size: 11px;
            margin: 1vw 0;
            line-height: 0.2vw;
        }
    }

    @media (max-width: 500px) {
        .outlinedLabel {
            font-size: 12px;
            margin: 1vw 0 2vw 0;
            line-height: 0.2vw;
        }
    }

    .outlinedInput {
        background-color: transparent;
        border-radius: 8px; /* Измените радиус границ */
        color: #fff;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: 'Montserrat';
        font-weight: 400; /* Измените толщину шрифта */
        letter-spacing: 0.02rem; /* Измените межбуквенный интервал */
        height: 48px; /* Измените высоту */
        line-height: 48px; /* Измените высоту строки */
        text-align: left;
        font-size: 16px; /* Измените размер шрифта */
        display: flex;
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .outlinedInput::placeholder {
        color: #969BAD;
        font-size: 16px; /* Измените размер шрифта */
        font-weight: 400; /* Измените толщину шрифта */
        line-height: 48px; /* Измените высоту строки */
        text-transform: none; /* Отмените преобразование текста в верхний регистр */
        letter-spacing: normal; /* Сбросьте межбуквенный интервал */
        margin-bottom: 0;
    }

    .outlinedInput:focus-within {
        border: 1px solid rgba(255, 255, 255, 1);
        box-shadow: none;
    }

    .outlinedInput:focus {
        outline: none;
    }

    .outlinedInput::placeholder {
        color: #969BAD;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 2vw;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        margin-bottom: 0.5rem;
    }

    .outlinedInput .MuiOutlinedInput-notchedOutline {
        border: none;
        transition: border-color 0.3s ease;
    }

    .outlinedInput.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: red;
    }

    @media (max-width: 1199.98px) {
        .outlinedInput {
            height: 5vw;
            line-height: 3vw;
            font-size: 1vw;
            border-radius: 0.6vw;
            border: 1px solid rgba(255, 255, 255, 0.3);
        }

            .outlinedInput::placeholder {
                font-size: 0.9rem;
                line-height: 2.5vw;
            }
    }

    @media (max-width: 991.98px) {
        .outlinedInput {
            height: 5vw;
            line-height: 4vw;
            font-size: 1.2vw;
            border: 1px solid rgba(255, 255, 255, 0.3);
            border-radius: 0.8vw;
        }

            .outlinedInput::placeholder {
                font-size: 1rem;
                line-height: 3vw;
            }
    }

    @media (max-width: 767.98px) {
        .outlinedInput {
            height: 10vw;
            line-height: 5vw;
            font-size: 3vw;
            background-color: transparent;
            border-radius: 2vw;
        }

            .outlinedInput::placeholder {
                font-size: 1.2rem;
                line-height: 4vw;
            }
    }

    @media (max-width: 575.98px) {
        .outlinedInput {
            height: 3vw;
            line-height: 6vw;
            font-size: 2vw;
            border-radius: 1.2vw;
        }

            .outlinedInput::placeholder {
                font-size: 1.4rem;
                line-height: 5vw;
            }
    }

    .inputAutoFillOverride:-internal-autofill-selected {
        appearance: menulist-button !important;
        background-image: none !important;
        background-color: transparent !important;
        color: inherit !important;
    }

    btnRecover {
        padding: 2vw;
        right: 0 !important;
    }
}
