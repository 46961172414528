@font-face {
    font-family: "PT Root UI";
    src: url("./fonts/PTRootUI-Light.woff2") format("woff2"),
        url("./fonts/PTRootUI-Light.woff") format("woff"),
        url("./fonts/PTRootUI-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PT Root UI";
    src: url("./fonts/PTRootUI-Bold.woff2") format("woff2"),
        url("./fonts/PTRootUI-Bold.woff") format("woff"),
        url("./fonts/PTRootUI-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PT Root UI";
    src: url("./fonts/PTRootUI-Medium.woff2") format("woff2"),
        url("./fonts/PTRootUI-Medium.woff") format("woff"),
        url("./fonts/PTRootUI-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PT Root UI";
    src: url("./fonts/PTRootUI-Regular.woff2") format("woff2"),
        url("./fonts/PTRootUI-Regular.woff") format("woff"),
        url("./fonts/PTRootUI-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Dela Gothic One";
    src: url("./fonts/DelaGothicOne-Regular.ttf") format("truetype"),
        url("./fonts/DelaGothicOne-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat Alternates";
    src: url("./fonts/MontserratAlternates-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat Alternates";
    src: url("./fonts/MontserratAlternates-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat Alternates";
    src: url("./fonts/MontserratAlternates-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat Alternates";
    src: url("./fonts/MontserratAlternates-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat Alternates";
    src: url("./fonts/MontserratAlternates-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

.dela-gothic-one-font {
    font-family: "Dela Gothic One", sans-serif;
}

@font-face {
    font-family: "Open Sans";
    src: url("./fonts/OpenSans-Bold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./fonts/OpenSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./fonts/OpenSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Sans Caption";
    src: url("./fonts/PTSansCaption-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Sans Caption";
    src: url("./fonts/PTSansCaption-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lexend Deca";
    src: url("./fonts/LexendDeca-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
