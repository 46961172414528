@keyframes keyframes-linearAnimate {
  0% {
    background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
  }

  .animatedLabel {
    animation: keyframes-linearAnimate 1.9s ease infinite;
    background: linear-gradient(90deg, rgba(61,202,171,1) 0%, rgba(252,252,252,1) 100%);
    background-size: 400% 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-style: normal;
    font-weight: bold;
    font-size: 1.2em;

    text-align: center;
    letter-spacing: 0.03em;
  }

  .x2buttonWrapper {
    grid-column: 2;
    grid-row: 1;
  }

  .dicesWrapper {
    border: 5px solid yellow;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    grid-column: 2;
    grid-row: 1;
    margin-bottom: 145px;
  }

  .enemyTurnWrapper {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 120px;
  }

  .dotsContainer {
    width: 7vw;
    margin: 0 auto;
  }

  .x2AndDicesWrapper {
    width: 232px;
    display: flex;
    align-items: center;
    padding-left: 15px;
  }

  @media screen and (min-width: 1600px) {
    .dicesWrapper {
      /*transform: scale(1.2);*/
      margin-bottom: 114px;
    }
    
    .enemyTurnWrapper {
      /*transform: scale(1.2);*/
    }

    .x2AndDicesWrapper {
      /*margin-bottom: 75px;*/
      /*padding-left: 45px;*/
    }
  }

  @media screen and (min-width: 1920px) {
    .dicesWrapper {
      /*transform: scale(1.3);*/
      margin-bottom: 150px;
    }
    
    .enemyTurnWrapper {
      /*transform: scale(1.3);*/
    }
    
    .x2AndDicesWrapper {
      /*padding-left: 65px;*/
    }
  }

@media screen and (min-width: 1024px) {
  .dicesWrapper {
    margin-bottom: 60px;
  }

}

@media screen and (min-width: 1400px) {
  .dicesWrapper {
    margin-bottom: 145px;
  }

}